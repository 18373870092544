import React from 'react';
import {BrowserRouter} from 'react-router-dom';
import Routes from './routes/Routes';
import ScrollToTop from './components/ScrollToTop';

const app = () => (
  <BrowserRouter>
    <ScrollToTop />
    <Routes />
  </BrowserRouter>
);

export default app;
